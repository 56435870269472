import React, { Component } from 'react';
import SwipeableViews from 'react-swipeable-views';
import {Map, InfoWindow,google, Marker, GoogleApiWrapper} from 'google-maps-react';
import './gallery.css'
import { LocationCity, RoomOutlined } from '@material-ui/icons';
const styles = {
  slide: {

    minHeight: 400,
    color: '#fff',
  },
  slide3: {
   backgroundColor: '#2196f3',
  },
 
};

export class MapContainer extends Component {
    state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
      };
     
      onMarkerClick = (props, marker, e) =>
        this.setState({
          selectedPlace: props,
          activeMarker: marker,
          showingInfoWindow: true
        });
     
      onMapClicked = (props) => {
        if (this.state.showingInfoWindow) {
          this.setState({
            showingInfoWindow: false,
            activeMarker: null
          })
        }
      };
       
    render() {
        const style = {
            width: '100%',
            height: '100%'
          }
          const triangleCoords = [
            {lat: 25.774, lng: -80.190},
            {lat: 18.466, lng: -66.118},
            {lat: 32.321, lng: -64.757},
            {lat: 25.774, lng: -80.190}
          ];
      return (
      <div class="gallery">
          <h3>LOCATION<RoomOutlined/></h3>
    <SwipeableViews enableMouseEvents>
   
<div  className="img" style={Object.assign({}, styles.slide, styles.slide3)}>
    
<Map  className="map"
          google={this.props.google}
          style={style}
          initialCenter={{
            lat:6.350 ,lng:-0.483
          }}
          zoom={8}
          onClick={this.onMapClicked}
       / >
</div>
    </SwipeableViews>
    </div>
  );
}
}

export default GoogleApiWrapper({
apiKey: ('AIzaSyCC7W9CTSPrbUXImwmLe810mwjn60qBnmw')
})(MapContainer)