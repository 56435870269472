import React from 'react'
import './rooms.css'
function Rooms() {
return (
<div className="rooms">
 
<div className="rooms_content">
<h4>Coperate Info</h4>
<p>Kumanini Holiday Inn Hotel is a quiet, 
     comfortable hotel located off the Kumasi- Accra
      road in an area called Osino- Eastern region. 
      Kumanini Holiday Inn Hotel offers ultimate 
      comfort and luxury. This multi-storied hotel 
      is a beautiful combination of traditional
       grandeur and modern facilities. The exclusive
        guest rooms are furnished with a range of 
        modern amenities such as television and free
         internet access. Guests comfortability and 
         peace of mind is our hall mark.
          </p>
<h4> Our Core Values</h4>
 <h5>SERVICE EXCELLENCE, LOYALTY, PASSIONATE, INNOVATIVE, INTEGRITY & RESPECT</h5>
<p>
     We are committed to the pursuit of SERVICE EXCELLENCE. <br/>
We epitomize LOYALTY and confidentiality by working collaboratively
 with our guests, employees, partners and suppliers to exceed shared goals.  <br/>
We are PASSIONATE about making a measurable and comfortable impact  <br/>
We consistently strive for INNOVATION that that makes a difference 
and to be at the forefront of global trends in terms of comfortability and serenity  <br/>
We exhibit INTEGRITY at all times.  <br/>
We RESPECT all our guests and ensure our relationships are mutually beneficial   <br/> 
</p>
<h4> Vision Statement </h4>
<p>To exude creativity, innovativeness and expertise in offering required hospitality service for local and international
      corporates and travelers.  </p>


</div>   
</div>
)
}

export default Rooms
