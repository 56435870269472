import React from 'react'
import './rooms.css'
function Rooms() {
return (
<div className="rooms">
 
<div className="rooms_content">
<h4>Meetings</h4>
<p>Kumanini	Hotel	provides	the	perfect	venue	for	all	your	conferences	and	events.	Our	conference	
room	is	designed	for	all	sorts	of	meetings,	conferences and	events.	It’s	designed and	equipped	
with	the	latest	technologies	to	suit	our	guests	need.	We	also	have	an	open	space	to	cater	for	all	
events.	

</p>
</div>   
</div>
)
}

export default Rooms
