import React from 'react'
import './rooms.css'
function Rooms() {
return (
<div className="rooms">
 
<div className="rooms_content">
<h4>Contact</h4>
<h5> Address: </h5> 
<p>P.O Box OI 37 </p>
<p> OSINO-EASTERN REGION  </p>

   <h5> PHONE: </h5>  
   <p>+233	244608036  </p> 
   <p>+233	508807233  </p>

<h5> Email: </h5> 
<p>Kumaninihotel@gmail.com  </p>



</div>   
</div>
)
}

export default Rooms
