import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import image1 from './images/one.jpg'
import image2 from './images/two.jpg'
import image3 from './images/three.jpg'
import image4 from './images/four.jpg'
import image5 from './images/five.jpg'
import image6 from './images/six.jpg'
import image7 from './images/seven.jpg'
import image8 from './images/eight.jpg'
import image9 from './images/nine.jpg'
import image10 from './images/ten.jpg'
import image11 from './images/eleven.jpg'
import image12 from './images/twelve.jpg'
import image13 from './images/sixteen.jpg'
import image14 from './images/fourteen.jpg'
import image15 from './images/fifteen.jpg'
import image16 from './images/sixteen.jpg'
import image17 from './images/seventeen.jpg'
import image18 from './images/eighteen.jpg'
import image19 from './images/nineteen.jpg'
import image20 from './images/twenty.jpg'
import image21 from './images/twenty1.jpg'
import image22 from './images/twenty2.jpg'
import image23 from './images/twenty3.jpg'
import image24 from './images/twenty4.jpg'
import image25 from './images/twenty5.jpg'
import image26 from './images/twenty6.jpg'
import image27 from './images/twenty8.jpg'
import './gallery.css'
import { Photo } from '@material-ui/icons';
const styles = {
  slide: {
    padding: 15,
    minHeight: 300,
    color: '#fff',
  },
  slide1: {
    backgroundColor: '#FEA900',
  },
  slide2: {
    backgroundColor: '#B3DC4A',
  },
  slide3: {
    backgroundColor: '#6AC0FF',
  },
  slide1: {
    backgroundColor: '#FEA900',
  },
  slide2: {
    backgroundColor: '#B3DC4A',
  },
  slide3: {
    backgroundColor: '#6AC0FF',
  },
  slide1: {
    backgroundColor: '#FEA900',
  },
  slide2: {
    backgroundColor: '#B3DC4A',
  },
  slide3: {
    backgroundColor: '#6AC0FF',
  },
  slide1: {
    backgroundColor: '#FEA900',
  },
  slide2: {
    backgroundColor: '#B3DC4A',
  },
  slide3: {
    backgroundColor: '#6AC0FF',
  },
};

function DemoSimple() {
  return (
      <div className="gallery">
          <h3>GALLERY<Photo/></h3>
    <SwipeableViews enableMouseEvents>
    <div className="img1" style={Object.assign({}, styles.slide, styles.slide1)}><img src={image1}/></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide2)}><img src={image3}></img></div>
<div  className="img1"  style={Object.assign({}, styles.slide, styles.slide3)}><img src={image5}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide1)}><img src={image7}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide2)}><img src={image9}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide3)}><img src={image11}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide1)}><img src={image13}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide2)}><img src={image15}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide3)}><img src={image17}></img></div>
<div className="img1" style={Object.assign({}, styles.slide, styles.slide1)}><img src={image19}/></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide2)}><img src={image21}></img></div>
<div  className="img1"  style={Object.assign({}, styles.slide, styles.slide3)}><img src={image25}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide1)}><img src={image27}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide2)}><img src={image2}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide3)}><img src={image4}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide1)}><img src={image6}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide2)}><img src={image8}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide3)}><img src={image10}></img></div>
<div className="img1" style={Object.assign({}, styles.slide, styles.slide1)}><img src={image12}/></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide2)}><img src={image14}></img></div>
<div  className="img1"  style={Object.assign({}, styles.slide, styles.slide3)}><img src={image16}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide1)}><img src={image18}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide2)}><img src={image20}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide3)}><img src={image22}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide1)}><img src={image24}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide2)}><img src={image26}></img></div>
<div  className="img1" style={Object.assign({}, styles.slide, styles.slide3)}><img src={image7}></img></div>
    </SwipeableViews>
    </div>
  );
}

export default DemoSimple;