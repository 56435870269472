import React from 'react'
import {Hidden} from '@material-ui/core'
import './menu.css'
import { Link} from 'react-router-dom'
function Menu() {
    return (
        <div className="menu">
        
             <Hidden mdUp>
             <div className="menu__list">
             <Link to="/">  <p>HOME</p>    </Link>   
          <Link to="/room">     <p>ROOMS</p>   </Link>
          <Link to="/meeting">   <p>MEETINGS</p>    </Link>
          <Link to="/restaurant">   <p>RESTAURANTS & BAR</p>   </Link>
          <Link to="/info">    <p>COPERATE INFO</p>    </Link>
          <Link to="/contact">    <p>CONTACT</p>    </Link>
        
           
            
             
            
            
        
            </div>  
            </Hidden>
        </div>
    )
}

export default Menu

