
import './App.css';
import Navbar from './Navbar';
import Carosel from './Carosel'
import Payment from './Payment'
import About from './About';
import Bookingcontent from './Bookingcontent';
import image5 from './images/Standard1A.jpg'
import image6 from './images/Executive1C.jpg'
import image8 from './images/Executive1A.jpg'
import image7 from './images/executive2A.jpg'
import {BrowserRouter as Router,
Switch,
Route,
Link
} from "react-router-dom";

import Rootypes from './Rootypes';
import WhatMakeUs from './WhatMakeUs';
import Gallery from './Gallery';
import Location from './Location';
import Footer from './Footer';
import Booking from './Booking';
import GuestDetail from './GuestDetails'
import Room from './Rooms'
import Contact from './Contact'
import  Restaurant  from './Restaurant';
import Meeting from './Metting'
import Coperate from './Coperate'
import { PersonAddDisabled, PersonOutlineSharp } from '@material-ui/icons';
function App() {
  return (
    <Router>
    <div className="app">
   
 
      <Switch>
       





      <Route path="/pay">

<Payment/>

</Route>
 <Route path="/info">
 <Navbar/>
 <Coperate/>
 <Footer/>
 </Route>
 <Route path="/guest">

 <GuestDetail/>

 </Route>
 <Route path="/booking">
   <div className="book">
<Booking/>
<div className="book1">
 <Bookingcontent

 id="9920w89uuwdn"
 image={image5}
 roomName="REGULAR"
 description="All	our	rooms	are	designed	to	fit	every	budget.
 The room is furnished with:
 Single	bed	
.Fully	furnished	air	conditioned
.	Flat	screen	TV
.	Wardrobe
.Wifi	connection
.	Breakfast	"
 guestNotes="Maximum Number Of Guests"
price={100}
noGuest={(<div><PersonOutlineSharp/>
  <PersonOutlineSharp/>
  
  </div>)}
 />
<Bookingcontent
id="98j9wc8ucsd8cusdcisdj"
 image={image8}
 roomName="EXECUTIVE 2"
 description="All	our	rooms	are	designed	to	fit	every	budget.
 The room is furnished with:
 .	Double	bed
.	Fully	furnished	air	conditioned	
.	Flat	screen	TV
.	Refrigerator	
.	Wardrobe
   .	Office	desk
   .	Water	heater	
   .	WiFi"
 guestNotes="Maximum Number Of Guests"
 price={190}
 noGuest={(<div><PersonOutlineSharp/>
  <PersonOutlineSharp/>
  
  </div>)}
 />
 <Bookingcontent
 noGuest={(<div><PersonOutlineSharp/>
  <PersonOutlineSharp/>
 
 </div>)}
 id="iciodsn9cudsc9dsc"
 image={image7}
 roomName="STANDARD"
 description="All	our	rooms	are	designed	to	fit	every	budget.
 The room is furnished with:
 Single	bed	
.	Fully	furnished	air	condition
.	Flat	screen	TV
.	Fridge	
   .Wardrobe	
   .	Water	heater"
 guestNotes="Maximum Number Of Guests"
 price={130}
 
 />
  <Bookingcontent

id="9920w89uuwdnnknkk"
image={image6}
roomName="EXECUTIVE 1"
description="All	our	rooms	are	designed	to	fit	every	budget.
The room is furnished with:
.	Double	bed
.	Fully	furnished	air	conditioned	
.	Flat	screen	TV
.	Refrigerator	
.	Wardrobe
  .	Office	desk
  .	Water	heater	
  .	WiFi"
guestNotes="Maximum Number Of Guests"
price={250}
noGuest={(<div><PersonOutlineSharp/>
  <PersonOutlineSharp/>
 
 </div>)}
/>
 </div>
 </div>
 </Route>
 <Route path="/restaurant">
 <Navbar/>
 <Restaurant/>
 <Footer/>

 </Route>
 <Route path="/meeting">
 <Navbar/>
 <Meeting/>
 <Footer/>
 </Route>
 <Route path="/room">
 <Navbar/>
 <Room/>
 <Footer/>
 
 </Route>
 <Route path="/contact">
 <Navbar/>
 <Contact/>
 <Footer/>
 </Route>
 <Route path="/">
 <Navbar/> 
    <Carosel />
    <About />
 <Rootypes />
 <WhatMakeUs />
 <Gallery />
 <Location/>
 <Footer/>

 </Route>
 </Switch>
    </div>

    </Router>
  );
}

export default App;
