import React from 'react'
import './whatmakeus.css'
import {
FastfoodOutlined,
MeetingRoomOutlined,
HdrStrongOutlined,
WifiOutlined,
LoyaltyOutlined


} from '@material-ui/icons'
function WhatMakeUs() {
return (
    <div className="makeus__main">
<h3>What Makes Us Unique</h3>
<div className="makeus__uni">
<div className="makeus">
<FastfoodOutlined/>
<h4>Cocktail & Restaurant</h4>  
<p>Our	restaurant	offers	delicious	Ghanaian	and	continental	dishes	and	our	snacks and cocktail is top notch which is served at the restaurant , bar and poolside. 
</p>
</div>   
<div className="makeus">
<MeetingRoomOutlined/>
<h4>Meetings & Events </h4>  
<p>Our	hotel	offers	an	elegant	conference	room for	conferences	and	meetings	and	an	open	space	
for	any	event	you	want	to	organize.

</p>
</div>     
<div className="makeus">
<HdrStrongOutlined/>
<h4>Health & Fitness</h4>  
<p>The	hotel	boasts	of	a	gym and	a	big	swimming	pool	where	guests	can	exercise	and	relax.	We	
take	into	consideration	the	health	of	our	guests
</p>
</div>  
      
</div>
<div className="makeusfoot">
<div className="makeus1">
<WifiOutlined/>
<h4>Wifi</h4>  
<p>Our	hotel	boasts	of	a	24/7	WiFi	connection	whereby	our	guests	can	access	the	internet	without	
any	interruptions.
</p>
</div>     
<div className="makeus1">
<LoyaltyOutlined/>
<h4>24/7 Support</h4>  
<p>Our	hotel’s	main	priority	is	our	guests comfort.	We’re	always	there	to	provide	any	support	to	
ensure	our	guests	are	comfortable	in	any	way. 
.</p>
</div>
</div>    
</div>
)
}

export default WhatMakeUs
