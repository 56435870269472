import React,{useState} from 'react';
import SwipeableViews from 'react-swipeable-views';
import './roomtype.css'
import Image from './images/Standard1A.jpg'
import Image1 from './images/Executive1B.jpg'
import Image2 from './images/Executive1C.jpg'
import Image3 from './images/Standard1B.jpg'
import Image4 from './images/Executive1A.jpg'
import Image5 from './images/Executive1E.jpg'
import Image6 from './images/Standard1B.jpg'
import Image7 from './images/fifteen.jpg'
import Image8 from './images/Executive1F.jpg'
import Image9 from './images/Standard1B.jpg'
import Image10 from './images/Standard1A.jpg'
import Image11 from './images/Standard1B.jpg'
import Image17 from './images/executive2A.jpg'
const styles = {
slide: {
minWidth:120,
minHeight: 400,
color: '#fff',
},
slide1: {
// backgroundColor: '#FEA900',
},
slide2: {
// backgroundColor: '#B3DC4A',
},
//   slide3: {
//     backgroundColor: '#6AC0FF',
//   },
};

function DemoSimple() {
const [state,setState] =useState(true);
const [state1,setState1] =useState(true);
const [state2,setState2] =useState(true);
const [state3,setState3] =useState(true);
const futherinfo=()=>{
if(state){

setState(false);
}

else{setState(true);}


}
const futherinfo1=()=>{
  if(state1){
  
  setState1(false);
  }
  
  else{setState1(true);}
  
  
  }
  const futherinfo2=()=>{
    if(state2){
    
    setState2(false);
    }
    
    else{setState2(true);}
    
    
    }
    const futherinfo3=()=>{
        if(state3){
        
        setState3(false);
        }
        
        else{setState3(true);}
        
        
        }
return (
<div className="dem">
<h3>Room types</h3>
<div className="demo_virtualize">

<div className="demo_virtual">
<SwipeableViews enableMouseEvents>
<div className="demo_img" style={Object.assign({}, styles.slide, styles.slide1)}><img src={Image9} /><p>1/3</p></div>
<div className="demo_img" style={Object.assign({}, styles.slide, styles.slide2)}><img src={Image10} /><p>2/3</p></div>
<div className="demo_img" style={Object.assign({}, styles.slide, styles.slide3)}><img src={Image11}/><p>3/3</p></div>
</SwipeableViews>
<h3>REGULAR</h3>
<p>All	our	rooms	are	designed	to	fit	every	budget</p>
<h4>Price: GH¢ 100</h4>
{state?(<button onClick={futherinfo}>Further Info</button>):(<button onClick={futherinfo}>Close</button>)} 
{state?"":(<div className="popReadMore">
<h3>Room datails</h3>
 <p> All	our	rooms	are	designed	to	fit	every	budget.
    This room is furnished with:</p>
    <li> Single	bed	 </li>
    <li>Fully	furnished	air	condition </li>
    <li>	Flat	screen	TV </li>
    <li>	Wardrobe </li>
    <li> Wifi	connection </li>
<li>Breakfast </li>
</div>)}
</div>
<div className="demo_virtual">
<SwipeableViews enableMouseEvents>
<div className="demo_img" style={Object.assign({}, styles.slide, styles.slide1)}><img src={Image1}/><p>1/3</p></div>
<div className="demo_img" style={Object.assign({}, styles.slide, styles.slide2)}><img src={Image4}/><p>2/3</p></div>
<div className="demo_img" style={Object.assign({}, styles.slide, styles.slide3)}><img src={Image7} /><p>3/3</p></div>
</SwipeableViews>
<h3>EXECUTIVE 2</h3>
<p>All	our	rooms	are	designed	to	fit	every	budget</p>
<h4>Price: GH¢ 190</h4>
{state1?(<button onClick={futherinfo1}>Further Info</button>):(<button onClick={futherinfo1}>Close</button>)} 
{state1?"":(<div className="popReadMore">
<h3>Room datails</h3>
<p> All	our	rooms	are	designed	to	fit	every	budget.
    This room is furnished with:</p>
    <li> Double	bed	 </li>
    <li>Breakfast </li>
    <li>Fully	furnished	air	condition </li>
    <li>	Flat	screen	TV </li>
    <li>	Wardrobe </li>
    <li> Wifi	connection </li>
    <li> Refrigerator</li>
    <li> Water heater</li>
    <li> Office Desk</li>

</div>)}
</div>
<div className="demo_virtual">
<SwipeableViews enableMouseEvents>
<div className="demo_img" style={Object.assign({}, styles.slide, styles.slide1)}><img src={Image17}/><p>1/3</p></div>
<div className="demo_img" style={Object.assign({}, styles.slide, styles.slide2)}><img src={Image17} /><p>2/3</p></div>
<div className="demo_img" style={Object.assign({}, styles.slide, styles.slide3)}><img src={Image17}/><p>3/3</p></div>
</SwipeableViews>
<h3>STANDRAD</h3>
<p>All	our	rooms	are	designed	to	fit	every	budget</p>
<h4>Price: GH¢ 130</h4>
{state2?(<button onClick={futherinfo2}>Further Info</button>):(<button onClick={futherinfo2}>Close</button>)} 
{state2?"":(<div className="popReadMore">
<h3>Room datails</h3>
<p> All	our	rooms	are	designed	to	fit	every	budget.
    This room is furnished with:</p>
    <li> Single	bed	 </li>
    <li>Breakfast </li>
    <li>Fully	furnished	air	condition </li>
    <li>	Flat	screen	TV </li>
    <li>	Wardrobe </li>
    <li> Wifi	connection </li>
    <li> Refrigerator</li>
    <li> Water heater</li>
</div>)}
</div>
{/* comming soon */}
<div className="demo_virtual">
<SwipeableViews enableMouseEvents>
<div className="demo_img" style={Object.assign({}, styles.slide, styles.slide1)}><img src={Image2}/><p>1/3</p></div>
<div className="demo_img" style={Object.assign({}, styles.slide, styles.slide2)}><img src={Image8} /><p>2/3</p></div>
<div className="demo_img" style={Object.assign({}, styles.slide, styles.slide3)}><img src={Image5}/><p>3/3</p></div>
</SwipeableViews>
<h3>EXECUTIVE 1</h3>
<p>All	our	rooms	are	designed	to	fit	every	budget</p>
<h4>Price: GH¢ 250</h4>
{state3?(<button onClick={futherinfo3}>Further Info</button>):(<button onClick={futherinfo3}>Close</button>)} 
{state3?"":(<div className="popReadMore">
<h3>Room datails</h3>
<p> All	our	rooms	are	designed	to	fit	every	budget.
    This room is furnished with:</p>
    <li> Double	bed	 </li>
    <li>Breakfast </li>
    <li>Fully	furnished	air	condition </li>
    <li>	Flat	screen	TV </li>
    <li>	Wardrobe </li>
    <li> Wifi	connection </li>
    <li> Refrigerator</li>
    <li> Water heater</li>
    <li> Office Desk</li>
</div>)}
</div>
</div>
</div>
);
}

export default DemoSimple;