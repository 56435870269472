import React from 'react'
import './rooms.css'
function Rooms() {
return (
<div className="rooms">
  
<div className="rooms_content">
<h4>Restaurant & Bar</h4>
<p>Our	restaurant	offers	delicious	Ghanaian	and	continental	dishes	and	our	snacks and	cocktail	is	
top	notch which	is	served	at	the	restaurant,	bar	and	poolside.	Our	dishes	are	finger	licking	good	
and	designed	to	meet	all	the	health	needs	of	our	guests from	starter	to	main	dishes	then	
desserts.	Our	cocktail	bar	has	a	variety	of	soft	drinks	to	hard	liquors	of	your	choice.

</p>
</div>   
</div>
)
}

export default Rooms
