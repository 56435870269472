import React,{useState} from 'react'
import './checkbooking.css'
import { useStateValue } from "./StateProvider";
function CheckBooking({id,hideButton, image,date, price, description,guestNotes,noGuest,roomName}) {
    const [currentRoom, setCurrentRoom] = useState(1)
  
    const changeRoom = (newRoom) => {
      setCurrentRoom(newRoom)
    }
const [{ basket }, dispatch] = useStateValue();
const removeFromBasket = () => {
// remove the item from the basket
dispatch({
type: 'REMOVE_FROM_BASKET',
id: id,
})
}


return (
<div className="checkbooking">

<div className="checkbooking_body">
<div className="checkbooking_body1">
<h6>Room Type</h6>
<p>{roomName}</p>
</div> 

<div className="checkbooking_body1">
<h6>Total Cost</h6>
<p>¢{price*currentRoom}</p>
</div>
{!hideButton && (
<button onClick={removeFromBasket}>Cancel</button>
)}
</div>        
</div>
)
}

export default CheckBooking
