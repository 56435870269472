


import React from 'react';

import { usePaystackPayment } from 'react-paystack';
import './App.css';
import './checkbooking.css'
import { useStateValue } from "./StateProvider";
import { getBasketTotal } from "./reducer";
import { Link, useHistory } from "react-router-dom";
function App() {
  const [{ basket }, dispatch] = useStateValue();
  const value=getBasketTotal(basket) // Part of the homework
  const history =useHistory()
  const config = {
    reference: (new Date()).getTime().toString(),
    email: "kumaninihotel@gmail.com",
    amount:value *100 ,
    currency: "GHS",
    publicKey: 'pk_live_444e834d50c1e200608bf7fbe80bee32dfb95221',
};

// you can call this function anything
const onSuccess = (reference) => {
 
  alert("refrence :",reference, "Payment sucessful ,please keep your refrence ")
  history.push("/")
  console.log(reference);
};

// you can call this function anything
const onClose = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
  console.log('closed')
}

const PaystackHookExample = () => {
    const initializePayment = usePaystackPayment(config);
    return (
      <div className="checkbooking_body1">
          <button onClick={() => {
              initializePayment(onSuccess, onClose)
          }}>Pay to keep your reservation</button>
      </div>
    );
};

  return (
    <div className="App">
   <div className="payment">
  <img src="https://nzmaths.co.nz/sites/default/files/images/uploads/users/3/hands.PNG"/>
<h3> Congratulations! </h3>  
<h3>Thanks for booking a room at Kumanini Holiday Inn Hotel.  </h3>  
  <h3> Please proceed to payment to complete your reservation. </h3>  
 
</div>
      <PaystackHookExample />
    </div>
  );
}

export default App;