import React from 'react'
import './footer.css'
import {Link} from 'react-router-dom'
import logo3 from './images/pep3a.png'
import {Facebook,Instagram} from '@material-ui/icons'
function Footer() {
    return (
        <div className="footer">
       <div className="footer__content">
            <div className="footer__content1">
            <div className="footer__content__logo">
            <img src={logo3}  />
            </div>
            <div className="footer__content__social">
            <Facebook/>
            <Instagram/>
            </div>   
            </div> 
            <div className="footer__content1">
            <h3>ABOUT US</h3>
            <p>Kumanini Hotel</p>
          <p>P.O.BOX OI 37 OSINO-EASTERN REGION</p>
          <p>TEL: +233244608036/+233508807233</p>
          <p>EMAIL : kumaninihotel@gmail.com</p>
          
            </div> 
            <div className="footer__content2">
      <h3>EXPLORE</h3>
      
      <Link to="/meeting">  <p>MEETINGS</p></Link>
      <Link to="/booking">  <p>RESERVATIONS</p></Link>
      <Link to="/contact"> <p>CONTACT</p></Link>
        
        
            </div>  
                
            </div>        
        </div>
    )
}

export default Footer
