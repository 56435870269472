import React,{useState} from 'react'

import './booking.css'

import { useStateValue } from "./StateProvider";
import { PersonSharp, Star } from '@material-ui/icons'
function Bookingcontent({id,hideButton, image,date, price, description,guestNotes,noGuest,roomName}) {
  const [currentRoom, setCurrentRoom] = useState(1)
  const [count, setCount] = useState(1);
  const changeRoom = (newRoom) => {
    setCurrentRoom(newRoom)
  }
  const [{ basket }, dispatch] = useStateValue();
    const[state,setState]=useState(true)
    const removeFromBasket = () => {
      setState(true)
      // remove the item from the basket
      dispatch({
          type: 'REMOVE_FROM_BASKET',
          id: id,
      })
      document.getElementById(id).style.opacity="1"
      document.getElementById(id).style.pointerEvents="initial"
  }
    const addToBasket = () => {
  setState(false)
      // dispatch the item into the data layer
      dispatch({
        type: "ADD_TO_BASKET",
        item: {
          id: id,
          roomName: roomName,
          noGuest: noGuest,
          guestNotes: guestNotes,
          image: image,
          price: price*count,
          description: description,
          date: date,
         currentRoom: currentRoom,
        },
      });
      document.getElementById(id).style.pointerEvents="none"
      document.getElementById(id).style.opacity="0.4"
    };
    console.log(basket)
    return (
        <div>
         <div className="booking__content_desc">
<div className="booking__content_descleft">
<div className="booking__content_descleft__img">
<img src={image}/>

</div> 
<div className="booking__content_descleftcontent">
{/* <h4>EXECUTIVE</h4>
<p>In publishing and graphic design,
Lorem ipsum is a placeholder text commonly
used to demonstrate the 
visual form of a document or a typeface
without</p> */}
<h4>{roomName}</h4>
<p>{description}</p>
<h5>{noGuest}</h5>

{/* <h6>used as a placeholder before final(2)Adult
    copy is available</h6> */}
<h6>{guestNotes}</h6>

</div> 
</div>   
<div className="booking__content_desright">
{/* <h5>Price $400</h5> */}
<h5>¢{price}</h5>
<div className="basketcontentright" id={id}>
 <button  onClick={() => setCount(count>1?count - 1 :1)}>-</button> 
  <p>{count}</p> 
  <button  onClick={() => setCount(count + 1)}>+</button>  
  
</div>
{state?(<h4 onClick={addToBasket}>Book Now</h4>):(<h4 onClick={removeFromBasket}>Cancel</h4>)}

</div>
</div> 

        </div>
    )
}

export default Bookingcontent
