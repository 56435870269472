import React from 'react'

import TextField from '@material-ui/core/TextField';
import DateRangePicker from '@material-ui/lab/DateRangePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import Box from '@material-ui/core/Box';
import { Link, useHistory } from "react-router-dom";
import { useStateValue } from "./StateProvider";
import { getBasketTotal } from "./reducer";
import CurrencyFormat from "react-currency-format";
import {ArrowBack} from '@material-ui/icons'
import './guestdetail.css'
import { usePaystackPayment } from 'react-paystack';
import CheckBooking from './checkbooking';
import emailjs from 'emailjs-com'

import './checkbooking.css'
import logo from "./logo.jpg";




function Contact({ id, hideButton }) {
// Payment

const [{ basket }, dispatch] = useStateValue();
const [value, setValue] = React.useState([null, null]);

const checkin=value[0];
const checkout=value[1];
console.log(checkin);
console.log(checkout);

const days =Math.ceil((checkout-checkin  ) / 8.64e7);
  
console.log(days);
const valuePrice=getBasketTotal(basket)*days // Part of the homework  
 console.log(valuePrice)

const history= useHistory()
// email ssrvices
function sendEmail(e) {
  e.preventDefault();

  emailjs.sendForm('service_e6li69l', 'template_bbgsn78', e.target, 'user_4zNvIpXvUadLvTCoCbOsG')
    .then((result) => {
    //    alert("Hello,please make payment to complete your reservation");
    //    history.push("./pay")
     document.getElementById('paybtn').click()
    }, (error) => {
        console.log(error.text);
        document.getElementById('error').innerHTML="NETWORK ERR .TRY AGAIN "
    });
}

const config = {
    reference: (new Date()).getTime().toString(),
    email: "kumaninihotel@gmail.com",
    amount:valuePrice *100 ,
    currency: "GHS",
    publicKey: 'pk_live_444e834d50c1e200608bf7fbe80bee32dfb95221',
};

// you can call this function anything
const onSuccess = (reference) => {
 
  alert("refrence :",reference, "Payment sucessful ,please keep your refrence ")
  history.push("/")
  console.log(reference);
};

// you can call this function anything
const onClose = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
  console.log('closed')
}


    const initializePayment = usePaystackPayment(config);
   
    











const removeFromBasket = () => {
    // remove the item from the basket
    dispatch({
        type: 'REMOVE_FROM_BASKET',
        id: id,
    })
}




// if(!basket.length){
//     document.getElementById('send').style.pointerEvents="none"
//     document.getElementById('send').style.opacity="0.9"


// }else{
//     document.getElementById('send').style.pointerEvents=""
//     document.getElementById('send').style.opacity="0.9"

// }

return (
<div className="contact">
   
<div className="contact__background">   

<div className="contact_content">
<Link to="/booking">
<ArrowBack/>
</Link>
<p>{setValue}</p>
<div >
<form  onSubmit={sendEmail} >
<div className="contact__inputs">
<p>GUEST DETAILS</p>
<div className="box">
<LocalizationProvider dateAdapter={AdapterDateFns}>
<DateRangePicker
startText="Check-in"
endText="Check-out"
value={value}
// checkin={value}
onChange={(newValue) => {
setValue(newValue);

}}
renderInput={(startProps, endProps,) => (
<React.Fragment>
<TextField required {...startProps} value={startProps} name="checkin"/>
<Box sx={{ mx: 1 }}> </Box>
<TextField required {...endProps} name="checkout" />

</React.Fragment>
)}
/>
</LocalizationProvider>
</div>
<TextField
required
id="outlined-required"
label="First Name"
fullWidth
variant="outlined"
size="small"
name="firstname"
/>
<TextField
required
id="outlined-required"
label="Last Name"
fullWidth
variant="outlined"
size="small"
name="lastname"
/>
<TextField
required
id="outlined-required"
label=" Email"
fullWidth
variant="outlined"
name="email"
size="small"

/>
<TextField
required
id="outlined-required"
label="Phone"
name="phone"
fullWidth
variant="outlined"
size="small"
/>
<TextField
required
id="outlined-required"
label="Country"
name="country"
fullWidth
variant="outlined"
size="small"
/>
<TextField
required
id="outlined-required"
label="Town/City"
fullWidth
variant="outlined"
name="city"
size="medium"
/>
<TextField

id="outlined-required"
label=" Post/Zip (optional)"
fullWidth
variant="outlined"
name="post"
size="small"
/>
<p>AdditionAL Information</p>
<TextField

id="outlined-required"
label="Special Reguest"
name="request"
fullWidth
variant="outlined"
size="small"
/>
<TextField

id="outlined-required"
label="Time to leave"
name="departure"
fullWidth
variant="outlined"
size="small"
/>
<p>Booking Details</p>
<p id ="error"></p>
<div className="bookinddetails">
{/* <div className="bookinddetails1"> */}
{/* <h4>CHECK-IN</h4>
<p>{value}</p>
</div> */}
{/* <div className="bookinddetails1">
<h4>CHECK-OUT</h4>  */}
{/* <p>{value}</p>    */}
{/* </div> */}
{/* <div className="bookinddetails1">
<h4>Pets</h4>
<p>Pets are not allowed</p>    
</div> */}
<div className="bookinddetails1">
<h4>Payment</h4>
<img src="https://logoeps.com/wp-content/uploads/2013/06/visa-card-vector-logo.png"/>
<img src="https://www.kindpng.com/picc/m/151-1514348_mtn-momo-logo-mobile-money-logo-png-transparent.png"/>
</div>
</div>
<p> Your Reservations</p>
<div className="bookinddetails1">
<h4>QUANTITY FOR ROOM TYPES</h4> 
<p>({basket.length})</p>   
</div>
{basket.map(item => (
            <CheckBooking
              id={item.id}
            
             roomName={item.roomName}
              price={item.price} 
            
            />
          ))}


<CurrencyFormat
renderText={(value) => (
<>
<p>
{/* Part of the homework */}
Subtotal ({basket.length} Rooms): <strong>{value}</strong>
</p>

</>
)}
decimalScale={2}
value={getBasketTotal(basket)*days} // Part of the homework
displayType={"text"}
// thousandSeparator={false}
prefix={"¢"}
/>

</div>

<div className="contact__footer">
<p>By sending this form I confirm that I have read and understand kumanini coperate info </p>
<input type="checkbox" required/>

<Link to="/info"><h6>Pivacy Policy</h6></Link>

</div>
<div className="contact__btn">
<h3></h3>
{basket.map(item => (
    <div>
<input name="room" type="hidden" value={item.roomName}/>
<input name="price" type="hidden" value={item.price}/>
</div>
))}
{!basket.length?(<Link to="/booking"><input id="send" value="Please Select A room"/></Link>):(<input id="send" type="submit"value="SEND"/>)}
</div>
</form>
</div>
<div className="checkbooking_body1">
          <button id="paybtn" onClick={() => {
              initializePayment(onSuccess, onClose)
          }}>Pay to keep your reservation</button>
      </div>
</div>   
</div>
</div>
)
}

export default Contact
