import React,{useState} from 'react'
import image4 from './images/4.jpg'
import './about.css'
import {Link} from'react-router-dom'
import CheckCard from './CheckCard';
function About() {
    const [state,setState]=useState(false)
const ShowModal=()=>{
if(state){

setState(false)
}
else{
    setState(true)
}
}





return (
<div className="about">
<div className="about__header">
<div className="about__header__text">
<p>Kumanini Holiday Inn</p>
</div> 
<div className="about__header__desc">
<p> 	Kumanini	Holiday	Inn	Hotel	is	a	luxury	hotel	located	in	Osino- Eastern	Region	which	offers	a	
conference	room	for	your	various	meetings	and	a	large	space	for	any	event.	
The	hotel	has	large	room	sizes	for	the	comfort	of	our	guests	and	the	interior	designed	to	suit	every	
guest’s	needs	and	relaxation.	<br/><br/>

		Our	rooms	are	very	affordable	and	tastefully	furnished	to	ensure	a	guest’s	comfort and	is	equipped	
with	the	latest	modern	technologies.
		Our	food	is	top	notch	and	we	make	sure	our	guest’s	health	is	our	main	priority.
Our	hotel	is	equipped	with	a	swimming	pool	for	your	exercise	and	relaxation,	a	conference	room	to	
hold	all	your	meetings,	a	restaurant	and	a cocktail bar</p>
</div> 
<div className="about__image">
<img src={image4}/>
</div> 
</div> 
<div className="about__footer">
<img src="https://img.icons8.com/ios-glyphs/90/000000/bedroom.png"/>
    
<div className="about__footer_banner">
<h4>Get Out And Stretch Your Magination </h4>
<h6>Plan  a different kind of getway 
to uncover the hidden germs near you.</h6>
<Link to="/booking"><h5 >BOOK NOW</h5></Link>
{/* {state?(<h5 onClick={ShowModal}>CHECKOUT</h5>):(<h5 onClick={ShowModal}>BOOK NOW</h5>)}
{state?(<p onClick={ShowModal}>+233277591063<br/>We support you 24/7</p>):(<p onClick={ShowModal}></p>)}
{state?(<div className="modal"><CheckCard/></div>):(<div className="modal"></div>)} */}
</div> 
</div>     
</div>
)
}

export default About
