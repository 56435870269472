import React from 'react'
import './rooms.css'
function Rooms() {
return (
<div className="rooms">
  
<div className="rooms_content">
<h4>Rooms</h4>
<p>Our	rooms	are	designed	to	suit	every	pocket.
From	the	guest	who	just	wants a	room	to	relax	to	the	guest who wants	a	room	to	have	fun.	We	
have	about	50	rooms	designed	to	cater	all	your	needs	which	are	spacious	and	equipped	with	the	
latest	technologies.

</p>
</div>   
</div>
)
}

export default Rooms
