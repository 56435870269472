import React,{useState} from 'react'
import './navbar.css'
import Menu from './Menu'
import logo1 from './images/pep2.png'
import logo2 from './images/pep3.png'
import logo3 from './images/pep3a.png'
import logo4 from './images/yes.png'
import {Link } from 'react-router-dom'
import {Hidden} from '@material-ui/core'
import ClearAllRoundedIcon from '@material-ui/icons/ClearAllRounded';
import {Close} from '@material-ui/icons/'
function Nav() {
    const [state,setState] =useState(false)
    const openNav=()=>{
if(state){
setState(false)

}
else{
    setState(true)

}


    }
    return (
        <div className="nav_main">
        <div className = "nav">
        <Hidden smDown>
        <img src={logo3}/>
            <div className = "nav_menu">
          <Link to="/">  <p>HOME</p>    </Link>   
          <Link to="/room">     <p>ROOMS</p>   </Link>
          <Link to="/meeting">   <p>MEETINGS</p>    </Link>
          <Link to="/restaurant">   <p>RESTAURANTS & BAR</p>   </Link>
          <Link to="/info">    <p>COPERATE INFO</p>    </Link>
          <Link to="/contact">    <p>CONTACT</p>    </Link>
        
           
            
             
            
            
        

            </div>
          </Hidden>

            </div>  
            {state?(<Menu/>):''}
            <Hidden smUp>
    <div className = "icon">
    <img src={logo3}/>
            
            {state?(<Close onClick={openNav}/>):(< ClearAllRoundedIcon fontSize="large" onClick={openNav}/>)}
            </div>
            </Hidden>
        </div>
    )
}

export default Nav
