import { Checkbox } from '@material-ui/core'
import { PersonSharp, Star } from '@material-ui/icons'
import React from 'react'
import {Link} from 'react-router-dom'
import { useStateValue } from "./StateProvider";
import './checkcard.css'

import {ArrowBack} from '@material-ui/icons'


function Booking(date) {

const [{ basket },{ basket1 }, dispatch] = useStateValue();

return (
<div className="booking">
{/* <h4>BOOKING</h4> */}
<div className="booking__content">
<div className="booking__content_header">
<div className="booking__content_header1">

<div className="contact_content">
<Link to="/">
<ArrowBack/>
</Link>  
</div> 

</div> 

<div className="booking__content_header2">
<p>Rooms Selected</p>
<h2>{basket.length}</h2>
<div className="chebtn">
<Link to="/guest"><h5>CHECK-OUT</h5></Link>
</div> 
</div>  
     
</div>


</div>     
 

</div>
)
}

export default Booking
